import React from "react";

export function FooterLayout() {
  const date = new Date();

  return (
    <footer className="footer">
      <span className="title">{`Copyright ${date.getFullYear()}`}</span>
    </footer>
  );
}
