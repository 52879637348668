import React, { PropsWithChildren } from "react";
import { Popover, Button, Avatar } from "antd";
import { routes } from "../routes";
import { Link, useLocation } from "react-router-dom";
import { ProfilNavbar } from "./profil";
import { isEmpty } from "lodash";

export const Navbar: React.FC<PropsWithChildren<{ showMenu?: boolean }>> = ({
  children,
  showMenu,
}) => {
  const [visible, setVisible] = React.useState(false);
  function handleOpen() {
    setVisible(!visible);
  }
  return (
    <header className={`header ${!visible ? "h-box" : ""}`}>
      <div className="row" style={{ alignItems: "center" }}>
        <div className="logo">
          <img src={require("../assets/images/logo.png")} alt="logo" />
        </div>
        <nav className="nav">
          <NavComponent />
        </nav>
        <div className="space"></div>
        <div onClick={handleOpen} className="nav--icon">
          {!visible && <i className="fa-regular fa-bars"></i>}
          {visible && <i className="fa-solid fa-xmark"></i>}
        </div>
        <div className={`sidebar-nav ${visible ? "open" : ""}`}>
          <NavComponent />
        </div>
      </div>
    </header>
  );
};

const NavComponent: React.FC<{ setVisible?: any }> = ({ setVisible }) => {
  function handleClose() {
    if (typeof setVisible === "function") {
      setVisible(false);
    }
  }
  const route = useLocation();

  function getIndex(path: string) {
    const index =
      `${path}` === route.pathname || `${path}/` === route.pathname ? 1 : -1;
    return index;
  }
  return (
    <ul className="nav--list">
      {routes
        .filter((p) => p.isMenu && p.layout === "/home")
        .map((p, i) => {
          return (
            <li key={`link__${i}`} className="nav--link">
              <Link
                className={`${getIndex(p.path) != -1 ? "active" : ""}`}
                to={`${p.path}`}
                onClick={handleClose}
              >
                <div className="row" style={{ alignItems: "center" }}>
                  {!isEmpty(p.icon) && (
                    <i className={`${p.icon} nav--icon`}></i>
                  )}
                  <span style={{ flex: 1, paddingLeft: "0.5rem" }}>
                    {p.label}
                  </span>
                </div>
              </Link>
            </li>
          );
        })}
    </ul>
  );
};
