import React from "react";
import { TitleComponent } from "../../components/title";
import { SwiperSlide, Swiper } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { CountCard } from "../../components/count";

export function PartnerHome() {
  const PartnerLogo: React.FC<{ image: any; title?: string }> = ({
    image,
    title,
  }) => {
    return (
      <div className="partner--logo">
        <img src={image} alt={title} />
      </div>
    );
  };

  return (
    <section className="partner" id="partner">
      <div className="container">
        <TitleComponent title="Nos partenaires" />
        <Swiper
          slidesPerView={4}
          spaceBetween={30}
          pagination={{ clickable: true }}
          modules={[Pagination, Autoplay]}
          className="partner--swiper"
          centeredSlides={false}
          autoplay={{ delay: 10000, disableOnInteraction: false }}
        >
          <SwiperSlide>
            <PartnerLogo image={require("../../assets/images/logo.png")} />
          </SwiperSlide>
          <SwiperSlide>
            <PartnerLogo image={require("../../assets/images/min.png")} />
          </SwiperSlide>
          <SwiperSlide>
            <PartnerLogo image={require("../../assets/images/bg-3.jpg")} />
          </SwiperSlide>
          <SwiperSlide>
            <PartnerLogo image={require("../../assets/images/bg-1.jpg")} />
          </SwiperSlide>
          <SwiperSlide>
            <PartnerLogo image={require("../../assets/images/bg-3.jpg")} />
          </SwiperSlide>
          <SwiperSlide>
            <PartnerLogo image={require("../../assets/images/bg-2.jpg")} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="partner--count">
        <div className="container">
          <div className="row" style={{ marginBottom: 20 }}>
            <div className="logo"></div>
            <div className="col partner--count-title">
              <div className="title">{`Rejoignez`}</div>
              <div className="subtitle">{`une grande équipe`}</div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <CountCard count={8} suffix="Partenaires" />
            </div>
            <div className="col">
              <CountCard count={500} suffix="Hôpitaux" plus />
            </div>
            <div className="col">
              <CountCard count={100000} suffix="Patients" plus />
            </div>
            <div className="col">
              <CountCard count={1000} suffix="Cliniciens" plus />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
