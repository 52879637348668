import { Typography } from "antd";
import React from "react";

export const TestmonyCard: React.FC<{
  job: string;
  name: string;
  comment: string;
  image: any;
}> = ({ comment, job, name, image }) => {
  return (
    <div className="testmony__card">
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div className="testmony__card-image">
          <img src={image} alt={name} />
        </div>
        <div className="col">
          <Typography.Paragraph className="text" ellipsis={{ rows: 3 }}>
            {comment}
          </Typography.Paragraph>
          <div className="name">{name}</div>
          <div className="job">{job}</div>
        </div>
      </div>
    </div>
  );
};
