import { Button, Typography } from "antd";
import React from "react";

export const Mockup: React.FC<{
  title?: string;
  content?: string;
  image?: any;
  label?: string;
}> = ({ content, image, label, title }) => {
  return (
    <React.Fragment>
      <section
        className="mockup"
        style={{
          background: `linear-gradient(#fff8,#f8f8f888),url('${image}')`,
        }}
      >
        <div className="mockup--content js-timeline--content">
          <Typography.Title className="title">{title}</Typography.Title>
          <p className="text">{content}</p>
          <div className="btn">
            <Button title={label}>{label}</Button>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
