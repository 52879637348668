import { Typography } from "antd";
import React from "react";

export const TitleComponent: React.FC<{
  title: string;
}> = ({ title }) => {
  return (
    <div className="heading--container">
      <Typography.Title level={2} className="heading">
        {title}
      </Typography.Title>
      <div className="separator--small"></div>
    </div>
  );
};
