import { upperFirst, upperCase, isEmpty, toUpper } from "lodash";
import { setLoading, setUserProfil } from "../../redux/userReducer";
import { getUid } from "./title";

class UserHelper {
  private key: string = "@lobiko_user";
  private setting_allow_sync_contacts: string =
    "@lobiko_setting@allow_sync_contact";
  private setting_contact_sync_date: string =
    "@lobiko_setting@contact_sync_date";

  async setCurrentUser(user: any, dispatch?: any) {
    /*if (typeof dispatch === "function") {
      let item: any = user;
      await userService
        .get(user.id)
        .then((response) => {
          item = response.data;
        })
        .catch(() => {});
      dispatch(setUserProfil(item));
    }
    await localStorage.setItem(this.key, JSON.stringify(user));*/
  }

  getUserName(user: any) {
    return `${upperFirst(user.firstName)} ${upperCase(user.lastName)}`;
  }

  setLogout(dispatch?: any) {
    localStorage.removeItem(this.key);
    if (typeof dispatch === "function") {
      dispatch(setUserProfil({}));
    }
  }

  async getUserProfil() {
    let item: any = await localStorage.getItem(this.key);
    if (item != null) {
      const data = JSON.parse(item);
      return data;
    }
    return null;
  }

  async setUserSetting(
    type: "setting-contact-allow-sync" | "setting-contact-sync-date",
    value: any
  ) {
    let elementKey = "";
    if (type === "setting-contact-allow-sync") {
      elementKey = this.setting_allow_sync_contacts;
    } else if (type === "setting-contact-sync-date") {
      elementKey = this.setting_contact_sync_date;
    }
    await localStorage.setItem(elementKey, JSON.stringify(value));
  }

  async getUserSetting(
    type: "setting-contact-allow-sync" | "setting-contact-sync-date"
  ) {
    let elementKey = "";
    if (type === "setting-contact-allow-sync") {
      elementKey = this.setting_allow_sync_contacts;
    } else if (type === "setting-contact-sync-date") {
      elementKey = this.setting_contact_sync_date;
    }
    let item: any = await localStorage.getItem(elementKey);
    if (item != null) {
      const data = JSON.parse(item);
      return data;
    }
    return null;
  }

  async initData(dispatch?: any) {
    if (typeof dispatch === "function") {
      dispatch(setLoading(false));
    }
  }

  getTitle(user: any) {
    const { firstName, lastName } = user;
    let result = "";
    if ((firstName ?? "").length > 0) {
      result = firstName.substring(0, 1);
    }
    if ((lastName ?? "").length > 0) {
      result += lastName.substring(0, 1);
    }
    return toUpper(result);
  }

  addMeta(
    value: string,
    name: string,
    type: "string" | "phone" | "text" | "email" | "web"
  ) {
    let meta = null;
    if (!isEmpty(value)) {
      meta = {
        metaKey: name,
        metaValue: value,
        metaType: type,
      };
    }
    return meta;
  }

  getSumPayment(items: any[]) {
    let result = "";
    const elements: any[] = [];
    items.forEach((p) => {
      const index = elements.findIndex((x) => x.currency === p.currency);
      if (index === -1) {
        elements.push({ currency: p.currency, amount: p.amount });
      } else {
        let element: any = elements[index];
        element.amount = p.amount + element.amount;
        elements[index] = element;
      }
    });
    let i = 0;
    elements.forEach((p) => {
      const amount = new Intl.NumberFormat("us-US", {
        maximumSignificantDigits: 2,
      }).format(p.amount);
      result += `${amount} ${toUpper(p.currency)} `;
      if (i < elements.length - 1) {
        result += " + ";
      }
      i++;
    });
    return result;
  }
}

export default new UserHelper();
