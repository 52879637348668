import React from "react";
import { ServiceCard } from "../../components/service";
import { TitleComponent } from "../../components/title";

export function ServiceHome() {
  return (
    <section className="service" id="service">
      <TitleComponent title="Nos services" />
      <div className="container">
        <ServiceCard
          description="A partir de votre localisation, de votre maladie, vos symptômes de souffrance, nous vous présentons des hôpitaux pouvant vous aider"
          title="Cherchez un hôpital"
          image={require("../../assets/images/bg-1.jpg")}
          label="lire plus..."
          first
        />
        <ServiceCard
          description="Ayez un compte Assunce afin d'accéder à votre dossier médical : de par tous les diagnostics jusqu'à tous les traitements. "
          title="Dossier médical "
          image={require("../../assets/images/bg-2.jpg")}
          label="lire plus..."
        />
        <ServiceCard
          description="Rejoignez notre grande équipe afin d’offrir à tous une santé protégée."
          title="Devenez notre partenaire "
          image={require("../../assets/images/bg-3.jpg")}
          label="lire plus..."
          first
        />
      </div>
    </section>
  );
}
