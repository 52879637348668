import { Layout, Result } from "antd";
import React from "react";
import { useSelector } from "react-redux";

export function NotFoundScreen() {
  const days = 0;
  /*const subscription = useSelector(
    (state: RootState) => state.user.subscription
  );
  const [days, setDays] = React.useState(-1);

  React.useEffect(() => {
    const { now, validatedAt } = subscription;
    const count = dateHelpers.getDifference(validatedAt, now, "days");
    setDays(count);
  }, [subscription]);*/
  return (
    <Layout
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Result
        status={days > 0 ? "404" : "403"}
        title={"Le chemin n'est pas trouvé"}
        subTitle={days > 0 ? "" : ""}
      />
    </Layout>
  );
}
