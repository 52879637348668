import { isEmpty, random } from "lodash";

const appName = "LOBIKO";
const refKey = "lobiko-ref";
const mapKey = "AIzaSyC-LGOLFYF_XiHhQv_EQnMk_iPfcJVEzro";
export declare type mapMarker = {
  title: string;
  position: google.maps.LatLngLiteral;
};

const setAppTitle = (value: string) => {
  document.title = `${appName}${!isEmpty(value) ? " | " : ""}${value}`;
};

const getUid = (value: string): string => {
  if (typeof value !== "string") {
    return "";
  }
  if (isEmpty(value)) {
    return "";
  }
  let response = "";
  const items = value.split("/");
  if (items.length > 0) {
    response = items[items.length - 1];
  }
  return response;
};

export function cleanHTML(str: string) {
  let response = "";
  if (!isEmpty(str)) {
    str = str.toString();
    response = str.replace(/<[^>]*>/g, "");
  }
  return response;
}
export function getLessonPoster(files: any[]) {
  const index = random(false);
  const images = files.filter((p) => p.typeMime.search("image") != -1);
  let response = `https://picsum.photos/800/600?random=${index}`;
  if (images.length > 0) {
    response = images[0].file;
  }
  return response;
}

export function getExtension(filename: string) {
  let result = "";
  const elem = /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
  if (!isEmpty(elem)) {
    result = elem?.[0] ?? "";
  }
  return result;
}

export { appName, getUid, refKey, setAppTitle, mapKey };
