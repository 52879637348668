import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface userData {
  current: any;
  list: any[];
  all: any[];
  newLearners: any[];
  profil: any;
  loading: boolean;
  floatUrl?: string;
}

const initialState: userData = {
  current: {},
  all: [],
  list: [],
  profil: {},
  newLearners: [],
  loading: true,
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setUserProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setUsers: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setNewUsers: (state, action: PayloadAction<any[]>) => {
      state.newLearners = action.payload;
    },
    setAllUsers: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFloatUrl: (state, action: PayloadAction<string>) => {
      state.floatUrl = action.payload;
    },
  },
});
export const {
  setAllUsers,
  setUser,
  setUserProfil,
  setUsers,
  setNewUsers,
  setLoading,
  setFloatUrl,
} = userSlice.actions;
export default userSlice.reducer;
