import React from "react";
import { HeaderHome } from "./home/header";
import { ServiceHome } from "./home/service";
import { PartnerHome } from "./home/partner";
import { RatingHome } from "./home/rating";
import { setAppTitle } from "../utils/helpers/title";

export function HomeScreen() {
  React.useEffect(() => {
    setAppTitle("Accueil");
  }, []);

  return (
    <React.Fragment>
      <main className="main">
        <HeaderHome />
        <ServiceHome />
        <PartnerHome />
        <RatingHome />
      </main>
    </React.Fragment>
  );
}
