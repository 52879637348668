import { routeInterface } from "../routes";
import { HomeScreen } from "../screens/home";
export const publicRoutes: routeInterface[] = [
  {
    component: HomeScreen,
    icon: "",
    isMenu: true,
    label: "Accueil",
    layout: "/home",
    name: "home",
    path: "/",
    subscribeable: false,
    title: "Accueil",
    priority: 1,
  },
  {
    component: null,
    icon: "",
    isMenu: true,
    label: "Services",
    layout: "/home",
    name: "service",
    path: "#service",
    subscribeable: false,
    title: "Services",
    priority: 2,
  },
  {
    component: null,
    icon: "",
    isMenu: true,
    label: "Apps",
    layout: "/home",
    name: "app",
    path: "#apps",
    subscribeable: false,
    title: "Apps",
    priority: 3,
  },
  {
    component: null,
    icon: "",
    isMenu: true,
    label: "Partenariat",
    layout: "/home",
    name: "partner",
    path: "#partner",
    subscribeable: false,
    title: "Apps",
    priority: 4,
  },
  {
    component: null,
    icon: "",
    isMenu: true,
    label: "Avis",
    layout: "/home",
    name: "review",
    path: "#review",
    subscribeable: false,
    title: "Avis",
    priority: 5,
  },
  {
    component: null,
    icon: "",
    isMenu: true,
    label: "FAQ",
    layout: "/home",
    name: "faq",
    path: "#faq",
    subscribeable: false,
    title: "FAQ",
    priority: 6,
  },
];
