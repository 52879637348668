import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mockup } from "../../components/mockup";
import Swip, { Autoplay, Pagination, Navigation } from "swiper";
import gsap from "gsap";

export const HeaderHome = ({}) => {
  return (
    <section id="home">
      <Swiper
        centeredSlides
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        onSlideChange={(sw: Swip) => {
          gsap.to(".js-timeline--content", { opacity: 0, duration: 0 });
          gsap.to(".js-timeline--content", {
            opacity: 0.3,
            duration: 1,
            delay: 1,
          }); //wait 1 second
          gsap.to(".js-timeline--content", {
            opacity: 1,
            duration: 1,
            delay: 1.8,
          }); //wait 2 seconds
        }}
      >
        <SwiperSlide>
          <Mockup
            content="accédez à votre dossier médical partout, indépendamment d'un hôpital"
            image={require("../../assets/images/bg-1.jpg")}
            label="Se connecter"
            title="Dossier médical dans votre poche "
          />
        </SwiperSlide>
        <SwiperSlide>
          <Mockup
            content="Nous aidons des hôpitaux dans des relations avec leurs patients "
            image={require("../../assets/images/bg-2.jpg")}
            label="Se connecter"
            title="Gestion des patients "
          />
        </SwiperSlide>
        <SwiperSlide>
          <Mockup
            content="Consultations conseils en ligne "
            image={require("../../assets/images/bg-3.jpg")}
            label="se connecter"
            title="Conseil médical "
          />
        </SwiperSlide>
      </Swiper>
    </section>
  );
};
