import { publicRoutes } from "./routes/public";

declare type layoutType = "/admin" | "/home" | "/";
export declare type userType = "student" | "responsible" | "teacher";

export interface routeInterface {
  name: string;
  label: string;
  path: string;
  layout: layoutType;
  isMenu: boolean;
  component: any;
  icon: any | null;
  iconTransition?: any | null;
  parent?: string;
  visibility?: userType[];
  subscribeable: boolean;
  title?: string;
  priority?: number;
  group?: string;
}

const routes: routeInterface[] = [];

function addRoutes(...list: routeInterface[][]) {
  for (let items of list) {
    items.map((element) => {
      const index = routes.findIndex((p) => p.name === element.name);
      if (index === -1) {
        routes.push(element);
      }
    });
  }
}

addRoutes(publicRoutes);

export { routes };
