import { Rate, Typography } from "antd";
import React from "react";

export const ReviewCard: React.FC<{
  value: number;
  total: number;
}> = ({ value, total }) => {
  return (
    <div className="review__card">
      <Rate
        count={total}
        defaultValue={value}
        value={value}
        allowHalf
        disabled
      />
      <Typography.Title className="title" level={2}>
        {`Avis des utilisateurs`}
      </Typography.Title>
    </div>
  );
};
