import React from "react";

export const CountCard: React.FC<{
  count: number;
  suffix?: string;
  plus?: boolean;
}> = ({ count, suffix, plus }) => {
  return (
    <div className="partner--count-text">
      <div>
        {plus && <span className="plus">{`+`}</span>}
        <span className="count">{count}</span>
      </div>
      <div className="title">{`${suffix}`}</div>
    </div>
  );
};
