import { Button, Typography } from "antd";
import React from "react";

export const ServiceCard: React.FC<{
  title?: string;
  description?: string;
  label?: string;
  image?: any;
  first?: boolean;
}> = ({ description, image, label, title, first }) => {
  return (
    <React.Fragment>
      <div className="row service--card" style={{ alignItems: "stretch" }}>
        <div className={`col-md-6 col-xs-12 image ${first ? "first" : "last"}`}>
          <img src={image} alt={title} className="img" />
        </div>
        <div
          className={`col-md-6 col-xs-12 content ${first ? "last" : "first"}`}
        >
          <Typography.Title level={3}>{title}</Typography.Title>
          <p className="text">{description}</p>
          <div>
            <Button type="link" title={label}>
              {label}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
