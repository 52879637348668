import React from "react";
import { TitleComponent } from "../../components/title";
import { SwiperSlide, Swiper } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { CountCard } from "../../components/count";
import { ReviewCard } from "../../components/review";
import { TestmonyCard } from "../../components/testmony";

export function RatingHome() {
  const PartnerLogo: React.FC<{ image: any; title?: string }> = ({
    image,
    title,
  }) => {
    return (
      <div className="partner--logo">
        <img src={image} alt={title} />
      </div>
    );
  };

  return (
    <section className="review" id="review">
      <div className="container">
        <ReviewCard value={4.2} total={5} />
      </div>
      <div className="container">
        <Swiper
          slidesPerView={1}
          pagination={{ clickable: true }}
          modules={[Pagination, Autoplay, Navigation]}
          className="partner--swiper"
          centeredSlides={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
          <SwiperSlide>
            <TestmonyCard
              comment={`Ask CDCR San Quintin State Prison 2008. We installed Purex dispensers throughout the prison to combat diseases…and it was a Roaring Success (as in Roaring Drunk) I mean we had Long lines of prisoners fist fighting to use them.`}
              image={`https://picsum.photos/200/300?random=1`}
              name="Jacques MBIYA"
              job="Assistant d'université"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestmonyCard
              comment={`Ask CDCR San Quintin State Prison 2008. We installed Purex dispensers throughout the prison to combat diseases…and it was a Roaring Success (as in Roaring Drunk) I mean we had Long lines of prisoners fist fighting to use them.`}
              image={`https://picsum.photos/200/300?random=2`}
              name="Jacques MBIYA"
              job="Assistant d'université"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestmonyCard
              comment={`Ask CDCR San Quintin State Prison 2008. We installed Purex dispensers throughout the prison to combat diseases…and it was a Roaring Success (as in Roaring Drunk) I mean we had Long lines of prisoners fist fighting to use them.`}
              image={`https://picsum.photos/200/300?random=3`}
              name="Jacques MBIYA"
              job="Assistant d'université"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}
